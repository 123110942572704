.particle-wrapper {
    position: relative;
    overflow: hidden;
    .bump {
        z-index: 2;
    }
}

.particle {
    position: absolute;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    box-shadow: 0 0 1rem rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.5);
}