@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
  font-family: "Karla", sans-serif;
  line-height: normal;
}

.container {
  width: 60vw;
  height: 60vh;
  position: absolute;
  left: 20vw;
  top: 20vh;
}

.question {
  font-size: 1.5rem;
  font-weight: 600;
}
.subquestion {
  font-size: 1.25em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

input,
textarea {
  font-family: inherit;
  background: none;
  border: none;
  border-bottom: rgba(255, 255, 255, 0.5) 2px solid;
  width: 100%;
  padding: 0.375rem 0.5rem;
  margin: auto auto 0;
  color: white;
}

input::placeholder,
textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

input:focus,
textarea:focus {
  outline: none;
  border-bottom: rgba(255, 255, 255, 1) 2px solid;
}

.myBtn {
  border: none;
  background-color: white;
  margin: 15px 10px 10px 0;
  height: 2rem;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.myBtn:hover {
  background-color: rgb(255, 255, 255, 0.5);
  cursor: pointer;
}

.myBtn:active {
  background-color: rgb(255, 255, 255, 0.5);
  cursor: pointer;
}

.select {
  display: inline-block;
  box-sizing: unset;
  background: rgba(255, 255, 255, 0.1);
  margin: 0.25rem 0.25rem 0.25rem 0;
  padding: 0.75rem;
  width: 10rem;
  transition: 0.1s;
  border-radius: 0.25rem;
  border: solid rgba(255, 255, 255, 0.3) 2px;
  color: white;
  font-family: "Karla", sans-serif;
}

.select:hover {
  background: rgba(255, 255, 255, 0.2);
  transition: 0.1s;
  cursor: pointer;
}

.select input {
  width: auto;
  float: right;
  margin: 3px 3px 0px 5px;
}

.select label {
  width: 100%;
}

.error {
  margin-top: 5px;
  margin-bottom: 0px;
  color: white;
}
