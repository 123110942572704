@font-face {
    font-family: 'Neue Haas Unica';
    src: local('Neue Haas Unica ExtraBlack'), local('Neue-Haas-Unica-ExtraBlack'),
    url('NeueHaasUnica-ExtraBlack.woff2') format('woff2'),
    url('NeueHaasUnica-ExtraBlack.woff') format('woff'),
    url('NeueHaasUnica-ExtraBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Rubik";
    src: url("Rubik-VariableFont_wght.ttf") format("opentype");
    font-style: normal;
}