dash-body {
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}

tbody tr:hover {
  cursor: pointer;
  filter: brightness(65%);
  transition: 0.1s;
}

tr {
  transition: 0.1s;
}

.green {
  background-color: rgb(147, 255, 147);
}

.yellow {
  background-color: rgb(255, 255, 133);
}

.red {
  background-color: rgb(255, 138, 138);
}

.container {
  margin: 0 auto;
  width: 80vw;
  max-height: 70vh;
}
