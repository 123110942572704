h3 {
    margin: 0;
    padding: 0;
}

ul {
    margin: 0;
}

li:not(:first-child) {
    padding-top: 0.5rem;
}
