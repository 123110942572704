@use "sass:math";
@import "~@fontsource/exo/index.css";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import "/src/font/style.css";

* {
    box-sizing: border-box;
    -webkit-text-size-adjust: none;
}

.row {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
}

.f-grow {
    flex-grow: 1;
}

.f-shrink {
    flex-shrink: 1;
}

.f-shrink-0 {
    flex-shrink: 0;
}

.f-wrap {
    flex-wrap: wrap;
}

$justifyMap: (
        flex-start: "s",
        center: "c",
        flex-end: "e",
        space-between: "b",
        space-around: "a",
        Space-evenly: "v",
);

$alignMap: (
        flex-start: "s",
        center: "c",
        flex-end: "e",
        stretch: "t",
        baseline: "l",
);

@each $justKey, $justVal in $justifyMap {
    @each $alignKey, $alignVal in $alignMap {
        .row-#{$justVal}#{$alignVal} {
            @extend .row;
            justify-content: $justKey;
            align-items: $alignKey;
        }

        .col-#{$justVal}#{$alignVal} {
            @extend .col;
            justify-content: $justKey;
            align-items: $alignKey;
        }
    }
}


.h-bar {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid black;
}

// border radius 1 - 4rem, 0.25rem increment
@for $i from 1 through 16 {
    $border_radius: 0.25rem * $i;
    .rounded-#{$i} {
        border-radius: $border_radius;
    }
}

// font sizes 50% - 400%
@for $i from 0 through 10 {
    $number: 50 + 10 * $i;
    $font_size: 50% + (10% * $i);
    .font-#{$number} {
        font-size: $font_size;
    }
}

@for $i from 1 through 34 {
    $number: 150 + 25 * $i;
    $font_size: 150% + (25% * $i);
    .font-#{$number} {
        font-size: $font_size;
    }
}

.no-bold {
    font-weight: normal;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.justified {
    text-align: justify;
}

.text-center {
    text-align: center;
}

@for $i from 1 through 20 {
    $num: 5 * $i;
    $percentage: 5% * $i;
    .w-#{$num} {
        width: $percentage;
    }

    .h-#{$num} {
        height: $percentage;
    }
}

@for $i from 1 through 11 {
    $amount: 8.3333333333333% * $i;
    .w-#{$i}-#{12} {
        width: $amount;
    }

    .h-#{$i}-#{12} {
        height: $amount;
    }
}