.container {
  margin: 0 auto;
  width: 80vw;
  max-height: 70vh;
}

.shadow {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.video {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: 15px;
}
